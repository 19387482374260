import { applyMiddleware, compose } from "redux";
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import thunk from "redux-thunk";
import auth from "./reducers/authReducer";
import courseReducer from "./reducers/courseReducer";
import classListReducer from "./reducers/classListReducer";
import manageFeeReducer from "./reducers/manageFeeReducer";
import mastersettingsReducer from "./reducers/mastersettingsReducer";
import emailReducer from "./reducers/emailReducer";
import registeredCourseReducer from './reducers/courseRegistered'
import website from "./reducers/websiteReducer";

const rootReducers = combineReducers({
  auth,
  website,
  courseReducer,
  classListReducer: classListReducer,
  manageFeeReducer: manageFeeReducer,
  mastersettingsReducer,
  emailReducer: emailReducer,
  enrolledCourse:registeredCourseReducer
});

const middlewareEnhancer = applyMiddleware(thunk);
const composeWithDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const composedEnhancers = composeWithDevTools(middlewareEnhancer);

const persistConfig = {
  key: "excel",
  storage: storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducers);

const store = configureStore({ reducer: persistedReducer }, composedEnhancers);

export default store;
