import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  courseList:[],
  mycourseList: [],
};
const courseReducer = createSlice({
  name: "course",
  initialState,
  reducers: {
    setCourseList: (state, action) => {
      const { items } = action.payload;
      return { ...state, courseList: items };
    },

    setRegisteredCourseList: (state, action) => {
      return {
        ...state,
        mycourseList: action.payload,
      };
    },
  },
});

export const { setCourseList, setRegisteredCourseList } = courseReducer.actions;
export default courseReducer.reducer;
