import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    enrolledCourseName:'',
}

const registeredCourseReducer = createSlice({
    name:"Enrolled Course",
    initialState,
    reducers:{
        setEnrolledCourseName :  (state,action) =>{
           return {
            ...state,
            enrolledCourseName:action.payload
           }
        }

    }
})

export const {setEnrolledCourseName} = registeredCourseReducer.actions;

export default registeredCourseReducer.reducer;