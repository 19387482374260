import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userData: "",
    countryList: "",
    stateProviceList: '',
    districtList: '',
};
const authReducer = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setUserData: (state, action) => {
            return { ...state, userData: action.payload };
        },
        setCountryList: (state, action) => {
            const { listCountries } = action.payload;
            return { ...state, countryList: listCountries.items };
        },
        setStateProvinceList: (state, action) => {
            const { listStateProvinces } = action.payload;
            return { ...state, stateProviceList: listStateProvinces.items, };
        },
        setDistrictList: (state, action) => {
            const { listDistricts } = action.payload;
            return { ...state, districtList: listDistricts.items, };
        },
    },
});

export const {
    setUserData,
    setCountryList,
    setStateProvinceList,
    setDistrictList
} = authReducer.actions;
export default authReducer.reducer;
