import { createSlice } from "@reduxjs/toolkit";

 const initialValue = {
    sentEmails:[],
    selectedStudentEmailList: [],
    isLoading: false,
    sendEmailLoader: false
 }

 const emailReducer = createSlice({
    name: "email",
    initialState: initialValue,
    reducers: {
        getAllSentEmails:(state,action) =>{
            return{
                ...state,
                sentEmails:action.payload,
            }
        },
        setSentEmails:(state,action) =>{
             return{
                ...state,
                sentEmails:state.sentEmails.unshift(action.payload),
             }
        },
        getSelectedStudentEmailList:(state,action) =>{
            return{
               ...state,
                 selectedStudentEmailList:action.payload,
            }
        },
        setLoadingState:(state,action) =>{
            return {
                ...state,
                isLoading:action.payload,
            }
        },
        sendEmailLoader:(state,action) =>{
            return {
               ...state,
                 sendEmailLoader:action.payload,
            }
        },
    }

 });

 export const {getAllSentEmails,setSentEmails,getSelectedStudentEmailList,setLoadingState,sendEmailLoader} = emailReducer.actions;

 export default emailReducer.reducer