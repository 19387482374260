import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bannerList: [],
    whoWeAre: [],
    faq: [],
    aboutUs: [],
    testimonial: [],
};
const websiteReducer = createSlice({
    name: "website",
    initialState,
    reducers: {
        setBannerList: (state, action) => {
            const { listBanners } = action.payload;
            if (listBanners && listBanners.items)
                return { ...state, bannerList: listBanners.items, };
        },
        setWhoWeAre: (state, action) => {
            const { listWhoWeAres, createWhoWeAre, updateWhoWeAre } = action.payload;
            if (listWhoWeAres && listWhoWeAres.items)
                return { ...state, whoWeAre: listWhoWeAres.items, };
        },
        setFaq: (state, action) => {
            const { listFaqs, createFaq, updateFaq, deleteFaq } = action.payload;
            if (listFaqs && listFaqs.items)
                return { ...state, faq: listFaqs.items, };
        },
        setAboutUs: (state, action) => {
            const { listAboutuses, createAboutUs, updateAboutUs, deleteAboutUs } = action.payload;
            if (listAboutuses && listAboutuses.items)
                return { ...state, aboutUs: listAboutuses.items, };
        },
        setTestimonial: (state, action) => {
            const { listTestimonials, createTestimonials, updateTestimonials, deleteTestimonials } = action.payload;
            if (listTestimonials && listTestimonials.items)
                return { ...state, testimonial: listTestimonials.items, };
        },
    },
});

export const {
    setBannerList,
    setWhoWeAre,
    setFaq,
    setAboutUs,
    setTestimonial
} = websiteReducer.actions;
export default websiteReducer.reducer;
