const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  classList: [],
  registedCourseIds: [],
  onlineListClasses: [],
  recordedListClasses: [],
};

const classListReducer = createSlice({
  name: "Class List",
  initialState,
  reducers: {
    getScheduledClass: (state, action) => {
      return {
        ...state,
        classList: action.payload,
      };
    },

    setRegisteredCourseIds: (state, action) => {
      return {
        ...state,
        registedCourseIds: action.payload,
      };
    },

    setOnlineClassList: (state, action) => {
      return {
        ...state,
        onlineListClasses:action.payload,
      };
    },

    setRecordedClassList: (state, action) => {
      return {
        ...state,
        recordedListClasses:action.payload,
      }
    },
  },
});

export const { getScheduledClass,setRegisteredCourseIds,setOnlineClassList,setRecordedClassList } = classListReducer.actions;
export default classListReducer.reducer;
