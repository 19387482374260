import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  classRoomLists: [],
  genderLists: [],
  maritalLists: [],
  empModesLists: [],
  paymentModeLists: [],
  paymentStatusLists: [],
  instalmentLists: [],
  enqSourcesLists: [],
  countryList: '',
  stateProviceList: '',
  districtList: '',
  currencyList: '',
  qualificationList: '',
  specializationList: '',
  designationList: '',
  holidayList: '',
};
const masterSettingsReducers = createSlice({
  name: "master",
  initialState,
  reducers: {
    setClassRoomList: (state, action) => {
      return {
        ...state,
        classRoomLists: action.payload,
      };
    },

    updateClassRoomLists: (state, action) => {
      const updatedList = [...state.classRoomLists, action.payload];
      return {
        ...state,
        classRoomLists: updatedList,
      };
    },
    setGenderList: (state, action) => {
      return {
        ...state,
        genderLists: action.payload,
      };
    },

    updateGenderList: (state, action) => {
      const updatedList = [...state.genderLists, action.payload];
      return {
        ...state,
        genderLists: updatedList,
      };
    },
    setMaritalList: (state, action) => {
      return {
        ...state,
        maritalLists: action.payload,
      };
    },
    updateMaritalStatusLists: (state, action) => {
      const updatedList = [...state.maritalLists, action.payload];
      return {
        ...state,
        maritalLists: updatedList,
      };
    },
    setEmpModeList: (state, action) => {
      return {
        ...state,
        empModesLists: action.payload,
      };
    },
    updateEmpModeLists: (state, action) => {
      const updatedList = [...state.empModesLists, action.payload];
      return {
        ...state,
        empModesLists: updatedList,
      };
    },
    setPaymentLists: (state, action) => {
      return {
        ...state,
        paymentModeLists: action.payload,
      };
    },
    updatePaymentModeLists: (state, action) => {
      const updatedList = [...state.paymentModeLists, action.payload];
      return {
        ...state,
        paymentModeLists: updatedList,
      };
    },
    setInstallmentList: (state, action) => {
      return {
        ...state,
        instalmentLists: action.payload,
      };
    },
    updateInstallMentLists: (state, action) => {
      const updatedList = [...state.instalmentLists, action.payload];
      return {
        ...state,
        instalmentLists: updatedList,
      };
    },
    setEnqSourceList: (state, action) => {
      return {
        ...state,
        enqSourcesLists: action.payload,
      };
    },
    updateEnqSourceLists: (state, action) => {
      const updatedList = [...state.enqSourcesLists, action.payload];
      return {
        ...state,
        enqSourcesLists: updatedList,
      };
    },
    setPaymentStatusList: (state, action) => {
      return {
        ...state,
        paymentStatusLists: action.payload,
      };
    },
    updatePaymentStatusList: (state, action) => {
      const updatedList = [...state.paymentStatusLists, action.payload];
      return {
        ...state,
        paymentStatusLists: updatedList,
      };
    },
    setCountryList: (state, action) => {
      const { listCountries, createCountry, updateCountry, deleteCountry } = action.payload;
      if (listCountries && listCountries.items)
        return { ...state, countryList: listCountries.items, };
      else if (createCountry)
        return { ...state, countryList: [...state.countryList, createCountry] };
      else if (updateCountry)
        return {
          ...state, countryList: state.countryList.map(item => {
            if (item.id === updateCountry.id)
              return updateCountry
            return item
          })
        };
      else if (deleteCountry)
        return { ...state, countryList: state.countryList.filter(item => item.id !== deleteCountry.id) };
    },
    setStateProvinceList: (state, action) => {
      const { listStateProvinces, createStateProvince, updateStateProvince, deleteStateProvince } = action.payload;
      if (listStateProvinces && listStateProvinces.items)
        return { ...state, stateProviceList: listStateProvinces.items, };
      else if (createStateProvince)
        return { ...state, stateProviceList: [...state.stateProviceList, createStateProvince] };
      else if (updateStateProvince)
        return {
          ...state, stateProviceList: state.stateProviceList.map(item => {
            if (item.id === updateStateProvince.id)
              return updateStateProvince
            return item
          })
        };
      else if (deleteStateProvince)
        return { ...state, stateProviceList: state.stateProviceList.filter(item => item.id !== deleteStateProvince.id) };
    },
    setDistrictList: (state, action) => {
      const { listDistricts, createDistrict, updateDistrict, deleteDistrict } = action.payload;
      if (listDistricts && listDistricts.items)
        return { ...state, districtList: listDistricts.items, };
      else if (createDistrict)
        return { ...state, districtList: [...state.districtList, createDistrict] };
      else if (updateDistrict)
        return {
          ...state, districtList: state.districtList.map(item => {
            if (item.id === updateDistrict.id)
              return updateDistrict
            return item
          })
        };
      else if (deleteDistrict)
        return { ...state, districtList: state.districtList.filter(item => item.id !== deleteDistrict.id) };
    },
    setCurrencyList: (state, action) => {
      const { listCurrencies, createCurrency, updateCurrency, deleteCurrency } = action.payload;
      if (listCurrencies && listCurrencies.items)
        return { ...state, currencyList: listCurrencies.items, };
      else if (createCurrency)
        return { ...state, currencyList: [...state.currencyList, createCurrency] };
      else if (updateCurrency)
        return {
          ...state, currencyList: state.currencyList.map(item => {
            if (item.id === updateCurrency.id)
              return updateCurrency
            return item
          })
        };
      else if (deleteCurrency)
        return { ...state, currencyList: state.currencyList.filter(item => item.id !== deleteCurrency.id) };
    },
    setHolidayList: (state, action) => {
      const { listHolidays, createHoliday, updateHoliday, deleteHoliday } = action.payload;
      if (listHolidays && listHolidays.items)
        return { ...state, holidayList: listHolidays.items, };
      else if (createHoliday)
        return { ...state, holidayList: [...state.holidayList, createHoliday] };
      else if (updateHoliday)
        return {
          ...state, holidayList: state.holidayList.map(item => {
            if (item.id === updateHoliday.id)
              return updateHoliday
            return item
          })
        };
      else if (deleteHoliday)
        return { ...state, holidayList: state.holidayList.filter(item => item.id !== deleteHoliday.id) };
    },
    setQualificationList: (state, action) => {
      const { listQualifications, createQualification, updateQualification, deleteQualification } = action.payload;
      if (listQualifications && listQualifications.items)
        return { ...state, qualificationList: listQualifications.items, };
      else if (createQualification)
        return { ...state, qualificationList: [...state.qualificationList, createQualification] };
      else if (updateQualification)
        return {
          ...state, qualificationList: state.qualificationList.map(item => {
            if (item.id === updateQualification.id)
              return updateQualification
            return item
          })
        };
      else if (deleteQualification)
        return { ...state, qualificationList: state.qualificationList.filter(item => item.id !== deleteQualification.id) };
    },
    setSpecializationList: (state, action) => {
      const { listSpecializations, createSpecialization, updateSpecialization, deleteSpecialization } = action.payload;
      if (listSpecializations && listSpecializations.items)
        return { ...state, specializationList: listSpecializations.items, };
      else if (createSpecialization)
        return { ...state, specializationList: [...state.specializationList, createSpecialization] };
      else if (updateSpecialization)
        return {
          ...state, specializationList: state.specializationList.map(item => {
            if (item.id === updateSpecialization.id)
              return updateSpecialization
            return item
          })
        };
      else if (deleteSpecialization)
        return { ...state, specializationList: state.specializationList.filter(item => item.id !== deleteSpecialization.id) };
    },
    setDesignationList: (state, action) => {
      const { listDesignations, createDesignation, updateDesignation, deleteDesignation } = action.payload;
      if (listDesignations && listDesignations.items)
        return { ...state, designationList: listDesignations.items, };
      else if (createDesignation)
        return { ...state, designationList: [...state.designationList, createDesignation] };
      else if (updateDesignation)
        return {
          ...state, designationList: state.designationList.map(item => {
            if (item.id === updateDesignation.id)
              return updateDesignation
            return item
          })
        };
      else if (deleteDesignation)
        return { ...state, designationList: state.designationList.filter(item => item.id !== deleteDesignation.id) };
    },
  },
});

export const {
  getClassRoomLists,
  updateClassRoomLists,
  setClassRoomList,
  updateGenderList,
  setGenderList,
  setMaritalList,
  updateMaritalStatusLists,
  setEmpModeList,
  upateEmpModeLists,
  setPaymentLists,
  updatePaymentModeLists,
  setInstallmentList,
  updateInstallMentLists,
  updateEmpModeLists,
  setEnqSourceList,
  updateEnqSourceLists,
  setPaymentStatusList,
  updatePaymentStatusList,
  setCountryList,
  setStateProvinceList,
  setDistrictList,
  setCurrencyList,
  setHolidayList,
  setQualificationList,
  setSpecializationList,
  setDesignationList,
} = masterSettingsReducers.actions;

export default masterSettingsReducers.reducer;
