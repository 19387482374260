import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
//import './index.css';
import "./assets/css/bootstrap.min.css";
import "./assets/css/lindo-fonts.css";
import "./assets/css/owl.carousel.min.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/meanmenu.css";
import "./assets/css/animate.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import "./assets/css/slider-style.css";
import "./assets/css/custom.css";
import { BrowserRouter } from "react-router-dom";
// import reportWebVitals from './reportWebVitals';
// AWS configuration
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import store from "./redux/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
Amplify.configure(awsExports);
const App = lazy(() => import("./App"));
const AppLoader = require("../src/assets/images/loader.gif");
const root = ReactDOM.createRoot(document.getElementById("root"));
const persistor = persistStore(store);

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Suspense
          fallback={
            <div className="appLoaderWrap">
              <img src={AppLoader} className="appLoder" />
            </div>
          }
        >
            <App />     
        </Suspense>
      </BrowserRouter>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// reportWebVitals();
