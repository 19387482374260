const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  managedFees: [],
};

const manageFeeReducer = createSlice({
  name: "Manage Fee",
  initialState,
  reducers: {
    getFeesLists: (state, action) => {
      return {
        ...state,
        managedFees: action.payload,
      };
    },
  },
});

export const { getFeesLists } = manageFeeReducer.actions;
export default manageFeeReducer.reducer;
